import { Mixpanel } from './mixPanel.js';
import { analyticsConstant } from '../Constants/analyticsConstants';
import { getMedicineName, getServiceName } from '../Services/common.js';

const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, { ...data });
};

export const KEYS = {
    ID: 'id',
    HOSPITAL_ID: 'Hospital Id',
    HOSPITAL_NAME: 'Hospital Name',
    PATIENT_ID: 'Patient Id',
    PATIENT_VISIT_ID: 'Patient Visit Id',
    MEDICINE_ID: 'Medicine Id',
    MEDICINE_NAME: 'Medicine Name',
    MEDICINE_MASTER_ID: 'Medicine Master Id',
    SERVICE_ID: 'Service Id',
    SERVICE_NAME: 'Service Name',
    SERVICE_MASTER_ID: 'Service Master Id',
    REPORT_NAME: 'Report Name',
    RESPONSE_TIME_MS: 'Response Time (ms)',
    FULL_URL: 'Full URL',
    URL: 'URL',
    MSG: 'message',
    STATUS: 'Status',
    SOLD_MEDICINE_NAME: 'Sold Medicine Name',
};

export const setUser = (data) => {
    Mixpanel.identify(data.email);
    Mixpanel.people.set({
        [KEYS.ID]: data?.id,
        $name: data?.name,
        role: data?.role,
        $email: data?.email
    });
}

export const trackPatientVisit = (hospitalName, hospitalId, patientId, patientVisitId) => {
    trackEvent(analyticsConstant.PATIENT_VISIT, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId,
        [KEYS.PATIENT_ID]: patientId,
        [KEYS.PATIENT_VISIT_ID]: patientVisitId
    });
};

export const trackMedicineSale = (hospitalName, hospitalId, medicines) => {
    medicines.forEach(medicine => {
        trackEvent(analyticsConstant.MEDICINE_SALE, {
            [KEYS.HOSPITAL_NAME]: hospitalName,
            [KEYS.HOSPITAL_ID]: hospitalId,
            [KEYS.MEDICINE_ID]: medicine.medicine,
            [KEYS.MEDICINE_NAME]: getMedicineName(medicine.medicine),
            [KEYS.MEDICINE_MASTER_ID]: medicine.product_detail_id
        });
    });
};

export const trackServiceSale = (hospitalName, hospitalId, services) => {
    services.forEach(service => {
        trackEvent(analyticsConstant.SERVICE_SALE, {
            [KEYS.HOSPITAL_NAME]: hospitalName,
            [KEYS.HOSPITAL_ID]: hospitalId,
            [KEYS.SERVICE_ID]: service.service,
            [KEYS.SERVICE_NAME]: getServiceName(service.service),
            [KEYS.SERVICE_MASTER_ID]: service.id
        });
    });
};

export const trackAadharSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.AADHAR_SEARCH, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId
    });
};

export const trackMobileNumberSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.MNO_SEARCH, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId
    });
};

export const trackFileNumberSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.FILE_SEARCH, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId
    });
};

export const trackOnlineIdSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.ONLINE_ID_SEARCH, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId
    });
};

export const trackWarning = (hospitalName, hospitalId, message) => {
    trackEvent(analyticsConstant.WARNING, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId,
        [KEYS.MSG]: message
    });
};

export const trackPatientStatusFilter = (hospitalName, hospitalId, status) => {
    trackEvent(analyticsConstant.PATIENT_STATUS_FILTER, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId,
        [KEYS.STATUS]: status
    });
};

export const trackPatientSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.PATIENT_SEARCH, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId
    });
};

export const trackPatientDateFilter = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.PATIENT_DATE_FILTER, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId
    });
};

export const trackSaleReturn = (hospitalName, hospitalId, saleReturnList) => {
    saleReturnList.forEach((sales) => {
        trackEvent(analyticsConstant.SALE_RETURN, {
            [KEYS.HOSPITAL_NAME]: hospitalName,
            [KEYS.HOSPITAL_ID]: hospitalId,
            [KEYS.SOLD_MEDICINE_NAME]: getMedicineName(sales.medicine)
        });
    });
};

export const trackReportView = (hospitalName, hospitalId, reportName) => {
    trackEvent(analyticsConstant.REPORT_VIEW, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId,
        [KEYS.REPORT_NAME]: reportName
    });
};

export const trackReportDownload = (hospitalName, hospitalId, reportName) => {
    trackEvent(analyticsConstant.REPORT_DOWNLOAD, {
        [KEYS.HOSPITAL_NAME]: hospitalName,
        [KEYS.HOSPITAL_ID]: hospitalId,
        [KEYS.REPORT_NAME]: reportName
    });
};

export const trackAPITime = (time, url, status) => {
    let baseUrl = url;

    try {
        baseUrl = url.includes('?') ? url.split('?')[0] : url;
    } catch (error) {
        console.error('Error extracting base URL:', error);
    }

    trackEvent(analyticsConstant.API_TIME, {
        [KEYS.RESPONSE_TIME_MS]: time,
        [KEYS.STATUS]: status,
        [KEYS.FULL_URL]: url,
        [KEYS.URL]: baseUrl
    });
};
