export const analyticsConstant = {
    PATIENT_VISIT: 'PATIENT_VISIT',
    MEDICINE_SALE: 'MEDICINE_SALE',
    SERVICE_SALE: 'SERVICE_SALE',
    AADHAR_SEARCH: 'AADHAR_SEARCH',
    MNO_SEARCH: 'MNO_SEARCH',
    FILE_SEARCH: 'FILE_SEARCH',
    ONLINE_ID_SEARCH: 'ONLINE_ID_SEARCH',
    WARNING: 'WARNING',
    PATIENT_STATUS_FILTER: 'PATIENT_STATUS_FILTER',
    PATIENT_SEARCH: 'PATIENT_SEARCH',
    PATIENT_DATE_FILTER: 'PATIENT_DATE_FILTER',
    SALE_RETURN: 'SALE_RETURN',
    REPORT_VIEW: 'REPORT_VIEW',
    REPORT_DOWNLOAD: 'REPORT_DOWNLOAD',
    API_TIME: 'API_TIME',
};