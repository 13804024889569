import * as storageConstants from '../Constants/storageConstant';
import { BROWSER_STORAGE_ERROR, } from '../Constants/stringConstants';
import { isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import * as apiHelper from "./ApiHelper";
import * as appConstants from "../Constants/appConstants";
import { formatValue } from 'react-currency-input-field';
import canAccessModule from '../Utils/permissionUtils';
import CryptoJS from 'crypto-js';

const localStorageArray = [storageConstants.AUTH, storageConstants.MASTER_DATA, storageConstants.SYSTEM_DATA, storageConstants.PERMISSIONS, storageConstants.DATES, storageConstants.CONFIG, storageConstants.INVESTIGATION, storageConstants.PRESCRIPTION_DOCTORS, storageConstants.USER_DATA, storageConstants.HOSPITAL_DATA];

const sessionStorageArray = [];

const secretKey = process.env.REACT_APP_NAME;

function encryptData(data) {
    return CryptoJS.AES
        .encrypt(typeof data === 'string'
            ? data
            : JSON.stringify(data), secretKey)
        .toString();
}

function decryptData(encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    try {
        return JSON.parse(decryptedText);
    } catch (e) {
        return decryptedText;
    }
}

export function getLocalData(name) {
    try {
        let storedData = null;

        if (sessionStorageArray.includes(name)) {
            storedData = sessionStorage.getItem(name);
        } else if (localStorageArray.includes(name)) {
            storedData = localStorage.getItem(name);
        }

        if (!storedData) return null;

        return decryptData(storedData);
    } catch (e) {
        console.error("Decryption error:", e);
        return null;
    }
}

export function storeLocalData(name, data) {
    try {
        const encryptedData = encryptData(data);

        if (sessionStorageArray.includes(name)) {
            sessionStorage.setItem(name, encryptedData);
        } else if (localStorageArray.includes(name)) {
            localStorage.setItem(name, encryptedData);
        }

        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        console.error("Encryption error:", e);
        return false;
    }
}

const darkenColor = (color, amount) => {
    let usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    const num = parseInt(color, 16);

    let r = (num >> 16) - amount;
    if (r < 0) r = 0;
    else if (r > 255) r = 255;

    let g = ((num >> 8) & 0x00FF) - amount;
    if (g < 0) g = 0;
    else if (g > 255) g = 255;

    let b = (num & 0x0000FF) - amount;
    if (b < 0) b = 0;
    else if (b > 255) b = 255;

    return (usePound ? "#" : "") + (r.toString(16).padStart(2, '0')) + (g.toString(16).padStart(2, '0')) + (b.toString(16).padStart(2, '0'));
};

export const setPrimarySecondaryColors = (color = '#44769b') => { // default primary
    const primaryColor = color;
    const secondaryColor = darkenColor(primaryColor, 20);

    document.documentElement.style.setProperty('--primary', primaryColor);
    document.documentElement.style.setProperty('--secondary', secondaryColor);
}

export function clearLocalStorageData() {
    try {
        localStorage.clear();
        return true;
    } catch (e) {
        return false;
    }
}

export const getMasterData = () => {
    return getLocalData(storageConstants.MASTER_DATA);
}

export const getSystemData = async () => {
    return getLocalData(storageConstants.SYSTEM_DATA);
}

export function isEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}

export const getTwoDecimalPlaces = (num) => {
    const n = num?.toString();
    return n?.includes('.')
        ? n?.substring(0, n.indexOf('.') + 3)
        : n;
}

export function isValidAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const n = a?.toString().replace(/,/g, '');
    return !isNaN(n);
}

export const formattedValue = (val, prefix, allowDecimals = true) => formatValue({
    value: `${val}`,
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix ? "₹" : "",
    intlConfig: prefix ? { locale: 'en-IN', currency: 'INR' } : null,
    decimalScale: (prefix || allowDecimals) ? 2 : null,
});

export function amountFormatter(amount, type = 'int', rounding = false) {
    if (isNaN(amount)) return amount;
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;

    return type === 'float'
        ? a?.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2, })
        : a?.toLocaleString("en-IN");
}

export function removeCommaFromAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const value = a?.toString().replace(/,/g, '');
    const v = getTwoDecimalPlaces(value);

    return type === 'int'
        ? parseInt(v)
        : parseFloat(v);
}

export const fetchMasterData = async (setLoading) => {
    setLoading && setLoading(true);
    await apiHelper.masterData().then(response => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            try {
                storeLocalData(storageConstants.MASTER_DATA, response?.data?.data);
            } catch (e) {
                warn(BROWSER_STORAGE_ERROR);
            }
        }
    });
}

export const fetchSystemData = async (setLoading) => {
    setLoading && setLoading(true);
    await apiHelper.systemData().then((response) => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            try {
                storeLocalData(storageConstants.SYSTEM_DATA, response?.data);
            } catch (e) {
                warn(BROWSER_STORAGE_ERROR);
            }
        }
    });
};

export const getGenderOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.genders) {
        const keys = Object.keys(data?.genders);
        const values = Object.values(data?.genders);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getDepartmentOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.departments) {
        return data?.departments?.map(d => ({
            label: d.name,
            value: d.id,
        }));
    } else return [];
}

export const getDepartmentName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.departments) {
        return data?.departments?.filter(d => d.id === parseInt(id))[0]?.name;
    } else return '';
};

export const getDoctorOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (Array.isArray(data?.doctors)) {
        return data?.doctors?.reduce((acc, curr) => {
            if (curr.status === 'active') {
                acc.push({
                    value: curr.id,
                    label: curr.name,
                });
            }

            return acc;
        }, []);
    } else return [];
}

export const getDoctorName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.doctors) {
        return data?.doctors?.filter(d => parseInt(d.id) === parseInt(id))[0]?.name;
    } else return '';
};

export const getMedicineTypes = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.product_type) {
        return data?.product_type?.map(m => ({
            value: m.id,
            label: m.name,
        }));
    } else return [];
}

export const getMedicineTypeById = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.product_type) {
        return data?.product_type?.filter(m => m.id === parseInt(id))[0]?.name;
    } else return "";
}

export const getMedicineOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.medicines) {
        return data?.medicines?.map(m => ({
            value: m.id,
            label: m.name,
            description: m?.description || '',
        }));
    } else return [];
}

export const getServiceOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.services) {
        return data?.services?.map(s => ({
            value: s?.id,
            label: `${s?.name} (${s?.type})`,
            price: s?.price,
            description: s?.description || '',
        }));
    } else return [];
}

export const getServiceDetails = (id) => {
    const data = getServiceOptions();

    if (id && data) {
        const matchedResult = data?.filter(s => s.value === parseInt(id));
        return matchedResult?.length > 0 ? matchedResult[0] : {};
    } else {
        return {};
    }
}

export const getServiceTypeOptions = (returnOnlyValue = false) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.service_type) {
        return returnOnlyValue
            ? data?.service_type?.map(s => s.id)
            : data?.service_type?.map(s => ({
                value: s.id,
                label: s.name,
            }));
    } else return [];
}

export const getMedicineName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.medicines) {
        return data?.medicines?.filter(m => m.id === parseInt(id))[0]?.name;
    } else return '';
};

export const getMedicineDesciption = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.medicines) {
        return data?.medicines?.filter(m => m.id === parseInt(id))[0]?.description;
    } else return '';
};

export const getSupplierOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.suppliers) {
        return data?.suppliers?.map(s => ({
            value: s.id,
            label: s.name,
        }));
    } else return [];
}

export const getSupplierName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.suppliers) {
        return data?.suppliers?.filter(s => s.id === parseInt(id))[0]?.name;
    } else return '';
};

export const getServiceName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.services) {
        return data?.services?.filter(s => s.id === parseInt(id))[0]?.name;
    } else return '';
};

export const getServiceTypeName = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (id && data?.service_type) {
        return data?.service_type?.filter(s => s.id === parseInt(id))[0]?.name;
    } else return '';
};

export const getSupplierAddress = (id) => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.suppliers && id) {
        return data?.suppliers?.filter(s => s.id === parseInt(id))[0]?.address;
    } else return '';
}

export const getPaymodeOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.payment_options) {
        const keys = Object.keys(data?.payment_options);
        const values = Object.values(data?.payment_options);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getYesNoOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.yes_no) {
        const keys = Object.keys(data?.yes_no);
        const values = Object.values(data?.yes_no);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getLivingOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.living_types) {
        const keys = Object.keys(data?.living_types);
        const values = Object.values(data?.living_types);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getEmploymentOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.employment_types) {
        const keys = Object.keys(data?.employment_types);
        const values = Object.values(data?.employment_types);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getEducationOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.education_types) {
        const keys = Object.keys(data?.education_types);
        const values = Object.values(data?.education_types);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getAgeGroupOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.age_groups) {
        const keys = Object.keys(data?.age_groups);
        const values = Object.values(data?.age_groups);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getMaritalStatusOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.marital_status) {
        const keys = Object.keys(data?.marital_status);
        const values = Object.values(data?.marital_status);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getDAMSOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.dams) {
        return data?.dams?.map(d => ({
            label: d.name,
            dam_id: d.id,
            value: d.id,
            everuse: true,
            currentuse: true
        }));
    } else return [];
}

export const splitBatchNumberForLabel = batchNo => {
    const splitted = batchNo?.split(' _ ');
    return splitted?.length > 1 ? `${splitted[0]?.trim()} _ ${splitted[1]?.trim()}` : '';
}

export const getBatchNumberOptions = (arr) => {
    if (arr?.length > 0) {
        return arr?.map(d => ({
            label: splitBatchNumberForLabel(d.batch_number),
            value: d.batch_number,
        }));
    } else return [];
}

export const getDiscountTypesOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.discount_types) {
        const keys = Object.keys(data?.discount_types);
        const values = Object.values(data?.discount_types);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getCityOptions = () => {
    const masterData = getLocalData(storageConstants.MASTER_DATA);

    if (masterData?.cities) {
        return masterData?.cities?.map(city => ({
            label: `${city.name}, ${city.state}`,
            value: city.id,
        }));
    } else return [];
}

export const getCityName = (id) => {
    const masterData = getLocalData(storageConstants.MASTER_DATA);

    if (masterData?.cities) {
        const city = masterData?.cities.filter(city => city.id === parseInt(id));
        return city?.length > 0 ? `${city[0].name}, ${city[0].state}` : '';
    } else return '';
}

export const getPatientStatusOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.patient_visit_status) {
        const keys = Object.keys(data?.patient_visit_status);
        const values = Object.values(data?.patient_visit_status);

        return keys?.map((k) => ({
            name: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getPatientStatusName = (key) => {
    if (key) {
        return getPatientStatusOptions()?.filter(s => s.value === key)[0]?.name;
    } else {
        return '';
    }
}

export const getUserRoleOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.roles) {
        const keys = Object.keys(data?.roles);
        const values = Object.values(data?.roles);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getUserRoleName = (key) => {
    if (key) {
        return getUserRoleOptions()?.filter(s => s.value === key)[0]?.label;
    } else {
        return '';
    }
}

export const getActiveInactiveOptions = () => {
    const data = getLocalData(storageConstants.MASTER_DATA);

    if (data?.status) {
        const keys = Object.keys(data?.status);
        const values = Object.values(data?.status);

        return keys?.map((k) => ({
            label: values[keys.indexOf(k)],
            value: k,
        }));
    } else return [];
}

export const getModuleOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.permissions) {
        return data?.permissions?.map(p => ({
            label: p?.module,
            value: p?.id,
        }));
    } else return [];
}

export const getReportOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.report_type) {
        return data?.report_type?.map(r => ({
            label: r?.value,
            value: r?.id,
        }));
    } else return [];
}

export const getInvestigationList = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data?.investigation) {
        return data?.investigation?.map(iv => ({
            label: iv?.value,
            value: iv?.id,
        }));
    } else return [];
}

export const getInvestigationName = id => {
    const data = getInvestigationList();

    if (data.length && id) {
        return data.find(iv => iv.value === parseInt(id))?.label;
    } else return '';
}

export const getInvoiceCopiesOptions = () => {
    return [{ label: "1 Copy", value: 0 }, { label: "2 Copies", value: 1 }, { label: "3 Copies", value: 2 }];
}

export const isSuperAdmin = () => {
    const r = getLocalData(storageConstants.USER_DATA)?.role;
    return r === appConstants.ROLES.SADMIN;
}

export const isHospitalAdmin = () => {
    const role = getLocalData(storageConstants.USER_DATA)?.role;
    return role === appConstants.ROLES.HOSPITAL_ADMIN;
}

export const isAdmin = () => {
    const role = getLocalData(storageConstants.USER_DATA)?.role;
    return role === appConstants.ROLES.ADMIN || role === appConstants.ROLES.HOSPITAL_ADMIN;
}

export const isDoctor = () => {
    const role = getLocalData(storageConstants.USER_DATA)?.role;
    return role === appConstants.ROLES.DOCTOR;
}

export const isPharmacist = () => {
    const role = getLocalData(storageConstants.USER_DATA)?.role;
    return role === appConstants.ROLES.PHARMACIST;
}

export const isCounsellor = () => {
    const role = getLocalData(storageConstants.USER_DATA)?.role;
    return role === appConstants.ROLES.COUNSELLOR;
}

export const isReceptionist = () => {
    const role = getLocalData(storageConstants.USER_DATA)?.role;
    return role === appConstants.ROLES.RECEPTION;
}

// Prescription can viewed by the role who can edit medicines, i.e, pharmacy and receptionist
export const canViewPrescription = (status) => {
    return canAccessModule(appConstants.MODULES.MEDICINES_MODULE)
        && (canEditPrescription(status)
            || canEditMedicine(status))
}

export const canEditPrescription = (status) => {
    return ((status?.toString().trim() === appConstants.AT_DOCTOR || status?.toString().trim() === appConstants.AT_PHARMACY) && isDoctor())
        || isAdmin();
}

export const canEditMedicine = (status) => {
    return status?.toString().trim() === appConstants.AT_PHARMACY
        || isAdmin()
        || isDoctor()
        || (!canAccessModule(appConstants.MODULES.MEDICINES_MODULE) && isReceptionist());
}

export const canViewPDF = (status) => {
    return status?.toString().trim() === appConstants.AT_PHARMACY || status?.toString().trim() === appConstants.COMPLETED || isAdmin();
}

export const getAmountCalculatedForMedicineOrder = (medicineData) => {
    let totalUnits = 0.00;
    let med_total = 0.00;
    let total_cgst = 0.00;
    let total_sgst = 0.00;
    let tax = 0.00;
    let discount = 0.00;
    let amount_due = 0.00;
    let fee = medicineData.fee;
    let discountType = medicineData.discountType;
    let discountValue = medicineData.discountValue;
    let grandTotal = 0.00;
    let subTotal = 0;

    [...medicineData?.arr, ...medicineData?.serviceArr].forEach(data => {
        med_total += parseFloat(getTwoDecimalPlaces(data?.unit_price)) * parseInt(data?.qty);
        totalUnits += parseInt(data.qty);

        switch (data.gstType) {
            case 'INCLUSIVE':
                const cgstValue = (data.unit_price * data.qty * parseFloat(getTwoDecimalPlaces(data.cgst))) / 100;
                let med_cgst = parseFloat(getTwoDecimalPlaces(cgstValue));

                const sgstValue = (data.unit_price * data.qty * parseFloat(getTwoDecimalPlaces(data.sgst))) / 100;
                let med_sgst = parseFloat(getTwoDecimalPlaces(sgstValue));

                med_total = med_total - (med_cgst + med_sgst);
                total_cgst += med_cgst;
                total_sgst += med_sgst;
                break;
            case 'EXCLUSIVE':
                const cgstVal = (data.unit_price * data.qty * parseFloat(getTwoDecimalPlaces(data.cgst))) / 100;
                total_cgst += parseFloat(getTwoDecimalPlaces(cgstVal));

                const sgstVal = (data.unit_price * data.qty * parseFloat(getTwoDecimalPlaces(data.sgst))) / 100;
                total_sgst += parseFloat(getTwoDecimalPlaces(sgstVal));
                break;
            default:
                break;
        }
    });

    let amount_before_tax = med_total + fee;

    tax = total_cgst + total_sgst;
    subTotal = amount_before_tax + tax;

    if (!isNullOrEmpty(medicineData.discountType) && !isNullOrEmpty(medicineData.discountValue)) {
        switch (medicineData.discountType) {
            case 'FLAT':
                discount = medicineData.discountValue >= subTotal ? subTotal : medicineData.discountValue;
                break;
            case 'PERCENTAGE':
                discount = medicineData.discountValue >= 100 ? subTotal : ((medicineData.discountValue * subTotal) / 100);
                break;
            default:
                break;
        }
    }

    grandTotal = subTotal - discount;

    return {
        totalUnits,
        total: amount_before_tax,
        tax,
        discount,
        amount_due,
        fee,
        discountType,
        discountValue,
        grandTotal,
        subTotal,
    }
}

export const canAccessPage = (pageName) => {
    if (Object.values(appConstants.PAGES).includes(pageName)) {
        const r = getLocalData(storageConstants.USER_DATA)?.role;

        const { SADMIN, ADMIN, DOCTOR, DOCTOR_ASSISTANT, PHARMACIST, COUNSELLOR, RECEPTION, MANAGER, HOSPITAL_ADMIN } = appConstants.ROLES;
        const { DASHBOARD, PATIENTS, PATIENT_VISIT, HELP, SALE_RETURN, MEDICINES, PURCHASE_MEDICINES, CHANGE_PASSWORD, DEPARTMENTS, SUPPLIERS, HOSPITALS, SERVICES, MEDICINE_TYPES, SERVICE_TYPES, MEDS, REPORTS, USERS, EXPORT } = appConstants.PAGES;

        const commonAccess = pageName === DASHBOARD || pageName === PATIENTS || pageName === PATIENT_VISIT || pageName === HELP || pageName === CHANGE_PASSWORD;

        const dataManagementAccess = pageName === DEPARTMENTS || pageName === SERVICE_TYPES || pageName === MEDICINE_TYPES || pageName === SUPPLIERS;

        const extraAccess = pageName === SALE_RETURN || pageName === MEDICINES || pageName === PURCHASE_MEDICINES;

        const superAdminAccess = pageName === DASHBOARD || pageName === HELP || pageName === CHANGE_PASSWORD || pageName === HOSPITALS || pageName === SERVICES || dataManagementAccess || pageName === MEDS;

        const adminAccess = commonAccess || pageName === SERVICES || extraAccess || dataManagementAccess || pageName === REPORTS || pageName === USERS;

        const hospitalAdminAccess = adminAccess || pageName === EXPORT;

        if (r === SADMIN) {
            return superAdminAccess;
        }
        else if (r === HOSPITAL_ADMIN) {
            return hospitalAdminAccess;
        }
        else if (r === ADMIN) {
            return adminAccess;
        }
        else if (r === PHARMACIST || r === MANAGER) {
            return commonAccess || extraAccess || pageName === SERVICES;
        }
        else if (r === DOCTOR || r === DOCTOR_ASSISTANT || r === RECEPTION || r === COUNSELLOR) {
            return commonAccess;
        }
        else return false;
    }
}

export const isConfigEnable = (key) => {
    const config = getLocalData(storageConstants.CONFIG);
    const foundConfig = config?.find(c => c?.key === key);
    return [foundConfig?.show === 'true', foundConfig?.mandatory === 'true'];
}
